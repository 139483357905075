<!-- 拜访详情 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="handleBox flex flex-ai-c">
        <el-page-header @back="goBack" content=""></el-page-header>
        <div class="" style="color:#333">
            <span style="marginRight:30px">客户名称：{{$route.query.name}}</span>
            <span>累计拜访次数：{{count}}</span>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="admin.name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="create_time" label="拜访时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="" label="拍照" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="width: 30px; height: 30px" :src="getImgUrl(scope.row.img[0].path)" :preview-src-list="getImgUrl(scope.row.img,'arr')" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:30px"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="位置" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="note" label="跟进" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        //返回上一页
        goBack() {
            this.$router.go(-1)
        },

        getList: function() {
            this.http.post('/admin.AdminDay/indexByUser', {
                curr: this.curr,
                row: this.row,
                user_id: this.$route.query.id,
                day_date: this.$route.query.day_date
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },

        // 图片转换
        getImgUrl(item, type) {
            var url
            if(type == 'arr') {
                url = item.map(i => {
                    return this.domain + i.path
                })
            } else {
                url = this.domain + item;
            }
            return url;
        },

        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
